<template>
  <div class="content">
    <div class="content-header">
      <div style="display: flex;flex-direction: column">
        <p class="content-header-text">bicoin <span style="font-weight: 600;">Hesabım</span></p>
        <p style="margin-top: 10px; color: #6a7685; font-size: 18px; line-height: 1.56;">
          bicoin’leri topla, öncelik, zaman ve görünürlük kazan. Yaptığın her satışta biriken bicoin’lerini, şimdilik iş ilanlarını öne çıkarmak veya alıcılara mesaj atmak için kullanabilirsin.
          <a href="/bicoin" target="_blank" style="color: #fd4056; cursor: pointer;">[detaylı bilgi]</a></p>
      </div>
    </div>
    <loader v-if="!pageLoaded"/>
    <div v-if="pageLoaded" class="bicoin-body" style="margin-top: 47px;">
      <div class="bicoin-total" style="display: flex">
        <div style="height: 110px; margin: auto 0 auto 35px; display: flex;">
          <img src="https://gcdn.bionluk.com/site/cicons/png/ic-bicoin-with-wave@2x.png" style="width: 110px; height: 110px">
          <div style="display: flex; margin: auto 0 auto 15px">
            <div class="inner-total-background">
              <p class="inner-title">hesabındaki toplam bicoin</p>
              <p class="inner-total-number">{{ totalBicoin }}</p>
            </div>


            <div  style="position:relative;margin-left: 38px; display: flex; height: 29px; align-items: center">
              <div>
                <img src="https://gcdn.bionluk.com/site/icon/tansel_info_gray.svg" onload="SVGInject(this)" class="warning-bicoin-icon"/>
              </div>
              <p style="margin-left: 5px" class="warning-desc"><span style="font-weight: normal">Bazı</span> İpuçları 😉</p>
              <div class="tooltiptext" style="visibility: visible">
                <help-box style="width: 358px"

                 :description="greenBoxInfoText"></help-box>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!gigs.length" class="create-first-gig">
        <div v-if="user.seller.oldGigCount" style="margin: 53px auto">
          <p class="title"><span style="font-weight: normal;">İlanlarını</span> Yönet</p>
          <p class="description" style="margin-top: 20px;">
            Sevgili <span style="color: #2d3640; font-weight: bold;">{{user.firstname}}</span>, aktif ilanın yok gözüküyor. İlanlarım sayfasından tüm ilanlarını yönetebilirsin.
          </p>
          <div class="create-gig-button" @click="$router.push('/panel/ilanlar')">
             İlanlarıma Git
          </div>
        </div>
        <div v-else style="margin: 53px auto">
          <p class="title"><span style="font-weight: normal;">İlk</span> İlanını Oluştur</p>
          <p class="description" style="margin-top: 20px;">
            Sevgili <span style="color: #2d3640; font-weight: bold;">{{user.firstname}}</span>, Yeteneklerini sergilemek için etkileyici bir iş ilanı oluştur ve bicoin’lerinle bu ilanı öne çıkar. Bionluk da sana iş bulsun!
          </p>
          <div class="create-gig-button" @click="$router.push('/panel/ilanlar/yeni')">
            İş İlanı Oluştur
          </div>
        </div>
      </div>
      <div v-else class="gigs-container">
        <div class="gigs-wrapper">
          <div v-for="(gig, index) in gigs" :key="index" class="gigs" style="position: relative">
            <div v-if="gig.isFeatured" class="featured-area" style="position: absolute; top: 0; right: 0; display: flex;">
              <div

                  data-balloon="Seçtiğin ilana ait ana kategoride daha önce öne çıkardığın başka bir ilanın var. Her ana kategoride 24 saat süresince, aynı anda sadece 1 ilanını öne çıkarabilirsin."
                   data-balloon-pos="up"
                   data-balloon-length="medium"
                   style="width: 19px; height: 19px; margin: auto"
              >
                <img src="https://gcdn.bionluk.com/site/cicons/ic-bia.svg" onload="SVGInject(this)" class="featured-icon"/>
              </div>
            </div>
            <div v-show="!gig.isFeatured && gig.isHovered" class="featured-area" style="position: absolute; top: 0; right: 0; display: flex;">
              <div data-balloon="İlanı öne çıkar"
                   data-balloon-pos="up"
                   data-balloon-length="medium"
                   style="width: 19px; height: 19px; margin: auto"
                   @mouseleave="gig.isHovered = false"
                   @click="activateUseBicoinModal(gig)"
              >
                <img src="https://gcdn.bionluk.com/site/cicons/ic-bicoinn.svg" onload="SVGInject(this)" class="featured-icon"/>
              </div>
            </div>
            <div v-show="!gig.isFeatured && !gig.isHovered" class="featured-area" style="position: absolute; top: 0; right: 0; display: flex; background-color: rgba(234, 237, 242, 0.5);">
              <div data-balloon="İlanı öne çıkar (500 bicoin)"
                   data-balloon-pos="up"
                   :data-balloon-visible="gig.showBalloon"
                   data-balloon-length="medium"
                   style="width: 19px; height: 19px; margin: auto"
                   @mouseenter="OnMouseEnter(gig)"
              >
                <img src="https://gcdn.bionluk.com/site/cicons/ic-bicoinn.svg" onload="SVGInject(this)" class="boost-icon"/>
              </div>
            </div>
            <div style="margin: 6px; display: flex">
              <img :src="gig.image_url" style="width: 146px; height: 82px"/>
              <div style="margin-left: 8px">
                <div  class="starArea">
                  <img style="width: 14px; height: 14px;" src="https://gcdn.bionluk.com/site/icon/icon_tansel_star_full.svg">
                  <p class="rate">{{ gig.commentRating }}</p>
                  <p class="count">({{ gig.commentCount }})</p>
                </div>
                <div class="description">
                  Ben, {{ gig.title }}
                </div>
              </div>
            </div>
            <div class="gig-footer">
              <div style="margin: 11px; display: flex; justify-content: space-between">
                <div style="display: flex">
                  <img :src="gig.sellerAvatarURL" style="width: 40px; height: 40px; border-radius: 50%; background-color: #bfc8d2;"/>
                  <div style="margin-left: 8px; height: 36px; display: flex; flex-direction: column; justify-content: space-between">
                    <p class="seller-username">{{ gig.sellerUsername }}</p>
                    <p class="seller-title">{{ gig.sellerTitle }}</p>
                  </div>
                </div>
                <div style="height: 40px;">
                  <p class="price">{{ gig.price }}<span>TL</span></p>
                  <p class="helpText">{{ gig.priceHelpText }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showSeemore" @click="retrieveGigs(true)" style="border-top: solid 1px rgba(139, 149, 161, 0.2); height: 67px; display: flex; cursor: pointer;">
          <p style="width: 117px; height: 18px; margin: auto; text-align: center; color: #5e6b79; font-size: 14px; font-weight: 600">
            <loader v-if="gigSeemoreLoader" style="width: 25px; height: 25px"/>
            <span v-else>Daha fazla göster</span>
          </p>
        </div>
      </div>
      <div v-for="(transaction, index) in transactions" :key="index" class="transaction-wrapper" style="margin-top: 30px">
        <div class="transaction">
          <div style="display: flex; justify-content: space-between">
            <div style="font-size: 14px; color: #5e6b79; font-weight: normal;">{{ transaction.name }}</div>
            <div style="font-size: 14px; color: #5e6b79; font-weight: normal;">{{ Number(transaction.created_at) | customDateFormat('dd MMM yyyy') }}</div>
          </div>
          <div class="description-box" style="margin: 20px auto 0">
            <div class="description">
              {{ transaction.desc }}
            </div>
          </div>
          <div class="transaction-footer" style="justify-content: space-between">
            <div style="display: flex;">
              <img src="https://gcdn.bionluk.com/site/cicons/png/bicoin_gold.png" style="width: 24px; height: 24px"/>
              <div style="margin-left: 10px; font-weight: bold; font-size: 20px;" :style="transaction.total > 0 ? 'color: #6dce6a' : 'color: #fd4056'">
                {{transaction.total}} <span style="font-weight: normal">bicoin</span>
              </div>
            </div>
            <div v-if="transaction.type === 7" style="display: flex">
              <div class="live-analyse-button" :class="transaction.isAnalyticsActive ? 'active': null" @click="retrieveAnalytics(transaction)" :style="transaction.buttonLoader ? 'opacity: 0.5' : null">
                <div style="margin: 2px 5px 0 15px">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-stats.svg" onload="SVGInject(this)" class="analytics-icon"/>
                </div>
                Canlı analizi görüntüle
              </div>
              <div class="analyse-label" :class="isLabelCompleted(transaction.created_at) ? 'completed': null" style="margin-left: 30px">
                {{ isLabelCompleted(transaction.created_at) ? 'Tamamlandı' : '24 saat başladı!' }}
              </div>
            </div>
          </div>
          <transition name="fade">
            <div v-if="transaction.type === 7 && transaction.isAnalyticsActive">
              <div class="separator"></div>
              <div class="analytics">
                <div class="item"
                     @mouseenter="transaction.statistics.views.active = true"
                     @mouseleave="transaction.statistics.views.active = false"
                >
                  <div  data-balloon="İş ilanının Öne Çıkanlar bölümünde kaç kez görüntülendiğini gösterir."
                        data-balloon-pos="up"
                        data-balloon-length="medium" style=" cursor: help;" class="title" :class="transaction.statistics.views.active ? 'active' : null">
                    <div
                         class="data-balloon"
                    >
                    <span v-show="!transaction.statistics.views.active">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="info-icon"/>
                    </span>
                      <span v-show="transaction.statistics.views.active">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="info-icon-active"/>
                    </span>
                    </div>
                    <p style="margin-left: 5px">
                      Görüntülenme
                    </p>
                  </div>
                  <div class="value" :class="transaction.statistics.views.active ? 'active' : null">
                    {{ transaction.statistics.views.value }}
                  </div>
                </div>
                <div class="item"
                     @mouseenter="transaction.statistics.click.active = true"
                     @mouseleave="transaction.statistics.click.active = false"
                >
                  <div  data-balloon="İş ilanının Öne Çıkanlar bölümünden kaç kez tıklandığını gösterir."
                        data-balloon-pos="up"
                        data-balloon-length="medium" class="title"  style=" cursor: help;" :class="transaction.statistics.click.active ? 'active' : null">
                    <div
                         class="data-balloon"
                    >
                    <span v-show="!transaction.statistics.click.active">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="info-icon"/>
                    </span>
                      <span v-show="transaction.statistics.click.active">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="info-icon-active"/>
                    </span>
                    </div>
                    <p style="margin-left: 5px">
                      Tıklanma
                    </p>
                  </div>
                  <div class="value" :class="transaction.statistics.click.active ? 'active' : null">
                    {{ transaction.statistics.click.value }}
                  </div>
                </div>
                <div class="item"
                     @mouseenter="transaction.statistics.engagements.active = true"
                     @mouseleave="transaction.statistics.engagements.active = false"
                >
                  <div data-balloon="Üye girişi yaparak ve sizi 'ilk kez' öne çıkanlar bölümünde gören ve mesaj atan tekil kullanıcı sayısı"
                       data-balloon-pos="up"
                       data-balloon-length="medium" class="title"  style=" cursor: help;" :class="transaction.statistics.engagements.active ? 'active' : null">
                    <div
                         class="data-balloon"
                    >
                    <span v-show="!transaction.statistics.engagements.active">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="info-icon"/>
                    </span>
                      <span v-show="transaction.statistics.engagements.active">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="info-icon-active"/>
                    </span>
                    </div>
                    <p style="margin-left: 5px">
                      İletişim
                    </p>
                  </div>
                  <div class="value" :class="transaction.statistics.engagements.active ? 'active' : null">
                    {{ transaction.statistics.engagements.value }}
                  </div>
                </div>
                <div class="item"
                     @mouseenter="transaction.statistics.convertion.active = true"
                     @mouseleave="transaction.statistics.convertion.active = false"
                >
                  <div class="title" style="text-align: center; width: 100%; display: flex; justify-content: center" :class="transaction.statistics.convertion.active ? 'active' : null">
                    <p style="text-align: center">
                      Dönüşüm
                    </p>
                  </div>
                  <div class="value" :class="transaction.statistics.convertion.active ? 'active' : null">
                    {{ transaction.statistics.convertion.value }}
                  </div>
                </div>
                <div class="item"
                     @mouseenter="transaction.statistics.earning.active = true"
                     @mouseleave="transaction.statistics.earning.active = false"
                >
                  <div data-balloon="Bu bicoin harcaması özelinde, sadece Öne Çıkanlar bölümünden sizinle etkileşime geçen kullancılardan aldığın sipariş toplamı"
                       data-balloon-pos="up"
                       data-balloon-length="medium" class="title" style=" display: flex; justify-content: center; width: 100%; cursor: help;" :class="transaction.statistics.earning.active ? 'active' : null">
                    <div
                         class="data-balloon"
                    >
                    <span v-show="!transaction.statistics.earning.active">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="info-icon"/>
                    </span>
                      <span v-show="transaction.statistics.earning.active">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="info-icon-active"/>
                    </span>
                    </div>
                    <p style="margin-left: 5px">
                      Kazanç
                    </p>
                  </div>
                  <div class="value" style="color: #6dce6a" :class="transaction.statistics.earning.active ? 'active-green' : null">
                    {{ transaction.statistics.earning.value }}
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>

      </div>


      <div v-if="seemorediv && !showLoader" @click="retrieveBicoinTransactions(true)"
           style="cursor: pointer; margin:20px 0;font-size: 14px; font-weight: 600;text-align: center; color: #2d3640;">
        Daha fazla gör +
      </div>
      <loader v-if="showLoader"/>
    </div>
  </div>
</template>

<script>
  import helpBox from "../../../components/HelpBox";
  const statisticsTemplate = () => ({
    views: {
      active: false,
      value: 0
    },
    click: {
      active: false,
      value: 0
    },
    convertion: {
      active: false,
      value: 0
    },
    engagements: {
      active: false,
      value: 0
    },
    earning: {
      active: false,
      value: 0
    }
  })
  export default {
    name: "src-pages-body-workstation-bicoin-v2",
    components:{helpBox},
    data() {

      return {
        greenBoxInfoText: '',
        showSeemore: false,
        gigSeemoreLoader: false,
        pageLoaded: null,
        isAnalyticsActive: false,
        buttonLoader: false,
        transactions: [],
        gigs: [],
        totalBicoin: 0,
        offset: 0,
        limit: 20,
        seemorediv: false,
        showLoader: false,
        gigLimit: 2,
        gigOffset: 0
      }
    },

    methods: {
      OnMouseEnter(gig){
        gig.showBalloon = false;
        gig.isHovered = true;
      },
      activateUseBicoinModal(gig) {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.USE_BICOIN, info: {uuid: gig.uuid}});
      },
      isLabelCompleted(createdAt) {
        return (+new Date() - Number(createdAt)) > 24*60*60*1000
      },
      retrieveGigs(seemore) {
        if (seemore) {
          this.gigSeemoreLoader = true;
          this.gigLimit = 10;
        }
        return this.api.seller.retrieveGigs(null, this.gigLimit, this.gigOffset, 1, 1)
        .then(({data: result}) => {
          const gigs = result.data.gigs.map((gig,i) => {
            gig.showBalloon = !seemore && i === 0 ? true : false
            gig.isHovered = false
            return gig
          })
          this.gigs = this.gigs.concat(gigs)
          this.showSeemore = result.data.seemorediv
          this.gigOffset += gigs.length
        })
        .finally(() => {
          this.gigSeemoreLoader = false
        })
      },
      retrieveAnalytics(transaction) {
        if(transaction.buttonLoader) return false;

        if (!transaction.isAnalyticsActive) {
          transaction.buttonLoader = true
          this.api.seller.getFeaturedAnalytics(transaction.external_id, 'gig', this.$Progress)
            .then(({data: result}) => {
              transaction.statistics.views.value = result.data.views
              transaction.statistics.click.value = result.data.click
              transaction.statistics.convertion.value = result.data.convertion
              transaction.statistics.engagements.value = result.data.engagements
              transaction.statistics.earning.value = result.data.earning

              transaction.isAnalyticsActive = !transaction.isAnalyticsActive
            })
            .catch(err => {
              this.$toasted.error(err);
            })
            .finally(() => {
              transaction.buttonLoader = false
            })
        } else {
          transaction.isAnalyticsActive = !transaction.isAnalyticsActive
        }
      },
      retrieveBicoinTransactions(seemore) {
        if (seemore) {
          this.showLoader = true;
          this.offset += this.limit;
        }
        return this.api.seller.retrieveBicoinTransactions(this.limit, this.offset)
          .then(({data}) => {

            let result = data;
            this.showLoader = false;

            if (result.success) {
              this.seemorediv = result.data.seemorediv;
              this.totalBicoin = result.data.totalBicoin;
              const transactions = result.data.bicoin_transactions.map(transaction => {
                transaction.isAnalyticsActive = false
                transaction.buttonLoader = false
                transaction.statistics = statisticsTemplate()
                return transaction
              })
              this.transactions = this.transactions.concat(transactions)
            } else {
              this.$toasted.error(result.msg);
            }
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      }
    },

    created() {
      this.EventBus.$emit("activateProgressBar")
      Promise.all([this.retrieveBicoinTransactions(), this.retrieveGigs()])
        .catch(() => {
          this.$Progress.fail()
        })
        .finally(() => {
          this.pageLoaded = true
          this.EventBus.$emit("deactivateProgressBar")
        })

      this.EventBus.$on("retrieveWithdrawFunds", (payload) => {
        this.payments.unshift(payload);
      });

      this.EventBus.$on("successBicoinTransaction", (payload) => {
        if (payload.gigId) {
          let foundGig = this.gigs.find(({ id }) => id === payload.gigId)
          if (foundGig) {
            foundGig.isFeatured = true
          }
        }
        this.totalBicoin = payload.response.totalBicoin;
        payload.response.bicoin_transaction.statistics = statisticsTemplate()
        payload.response.bicoin_transaction.isAnalyticsActive = false
        payload.response.bicoin_transaction.buttonLoader = false
        this.transactions.unshift(payload.response.bicoin_transaction);
      });

      this.greenBoxInfoText =  this.user.info.total_bicoin !== 1000 ? "<ul style='margin-left:20px; margin-top:5px;list-style:disc'><li>İlan öne çıkartmak için <span style='font-weight:bold'>en az 500 bicoin’in</span> potansiyel bir alıcı ile mesajlaşmak için ise <span style='font-weight:bold'>en az 20 bicoin’in</span> olması gerekiyor. <span style='font-weight:bold'>Bicoin’ler parayla satılmaz,</span> ne kadar satış yaparsan o kadar çok bicoin’in olur.</li></ul>"

      :
        "<ul style='margin-left:20px; margin-top:5px;list-style:disc'><li>Öne çıkartmak istediğin iş ilanı, kategori ve alt kategori sayfalarında <span style='font-weight:bold'>24 saat boyunca “Öne Çıkanlar”</span> bölümünde sabit olarak gözükür. Sadece aktif durumdaki iş ilanını öne çıkarabilirsin.</li></ul>"
      ;
    },

    beforeDestroy() {
      this.EventBus.$off('successBicoinTransaction');
    }
  }
</script>

<style scoped lang="scss">



  .tooltiptext {
    margin-top: 5px;
    display: block;
    visibility: hidden;
    position: absolute;
    margin-left: -5px;
    width: 372px;
    height: 100px;
    background-color: #00a575;
    color: #fff;
    text-align: center;
    padding: 5px 3px;
    border-radius: 4px;
    z-index: 1;
    left: 0;
    top: 36px;
    font-size: 13px;
    line-height: 1.38;
    font-weight: 400;

  }

  .tooltiptext::after {
    content: " ";
    position: absolute;
    left: 15px;
    bottom: 100%;
    margin-left: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent #00a575 transparent;
  }

  .content {
    margin-left: 56px;
    .content-header-text{
      font-size: 34px;
      font-weight: 600;
      color: #2d3640;
    }
    .content-header{
      margin-top: 25px;
      margin-bottom: 13px;
      width: 849px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .bicoin-body{
      width: 844px;
      margin-bottom: 100px;
    }
    .bicoin-total {
      width: 844px;
      height: 180px;
      object-fit: contain;
      border-radius: 10px;
      background-image: linear-gradient(to right, #2d3640 0%, #5e6b79 100%);
      .inner-total-background{
        width: 170px;
        height: 60px;
        border-radius: 5px;
        border: solid 1px rgba(45, 54, 64, 0.4);
        background-color: rgba(255, 255, 255, 0.2);
        padding: 15px 8px;
        .inner-title{
          font-size: 12px;
          font-weight: normal;
          text-align: center;
          color: #ffffff;
        }
        .inner-total-number {
          font-size: 46px;
          font-weight: bold;
          text-align: center;
          color: #fd4056;
        }
      }
      .warning-bicoin-icon /deep/ {
        margin-top: 5px;
        width: 20px;
        height: 20px;
        path {
          fill: #ffffff!important;
        }
      }
      .warning-desc{
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
      }
    }
    .create-first-gig{
      width: 844px;
      height: 283px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
      border: solid 1px #eaedf2;
      background-color: #ffffff;
      margin-top: 36px;
      text-align: center;
      .title{
        width: 224px;
        font-size: 28px;
        font-weight: bold;
        color: #2d3640;
        margin: auto;
      }
      .description {
        width: 543px;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.63;
        letter-spacing: normal;
        color: #5e6b79;
        margin: auto;

      }
      .create-gig-button{
        width: 152px;
        height: 46px;
        line-height: 45px;
        background-color: #fd4056;
        color: #ffffff;
        cursor: pointer;
        margin: 30px auto 0;
      }
    }
    .gigs-container{
      margin-top: 30px;
      width: 844px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
      border: solid 1px #eaedf2;
      background-color: #ffffff;
      .gigs-wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 30px 50px;
        .gigs{
          width: 333px;
          height: 155px;
          border-radius: 5px;
          box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
          border: solid 1px #eaedf2;
          background-color: #ffffff;
          &:nth-child(2n) {
            margin-left: 74px;
          }
          &:nth-child(n+3) {
            margin-top: 30px;
          }
          .featured-icon /deep/{
            width: 19px;
            height: 19px;
            path{
              fill: #ffffff;
            }
          }
          .boost-icon /deep/{
            width: 19px;
            height: 19px;
            path{
              fill: #fd4056;
            }
          }
          .featured-area{
            width: 38px;
            height: 36px;
            border-radius: 0 5px 0 5px;
            background-color: #fd4056;
          }
          .starArea{
            display: flex;
            align-items: center;
            height: 30px;
            img{
              width: 16px;
              height: 16px;
            }
            .rate{
              margin-left: 3px;
              font-size: 12px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #ffbe00;
            }
            .count{
              margin-left: 2px;
              font-size: 12px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #2d3640;
            }
          }
          .description{
            width: 167px;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.29;
            letter-spacing: -0.3px;
            color: #2d3640;
            height: 54px;
            overflow: hidden;
            word-break: break-word;

          }
        }
        .gig-footer{
          width: 333px;
          height: 60px;
          background-color: rgba(234, 237, 242, 0.1);
          border-top: solid 1px rgba(234, 237, 242, 0.5);
          .seller-username{
            color: #2d3640;
            font-size: 14px;
            font-weight: bold;
          }
          .seller-title{
            color: #8b95a1;
            font-size: 13px;
            font-weight: 300;
          }
          .helpText{
            font-size: 11px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: #8b95a1;
          }
          .price{
            font-size: 26px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: -1.5px;
            margin-top: -8px;
            text-align: right;
            color: #39d1a2;
            span{
              font-size: 18px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;

              letter-spacing: normal;
              text-align: center;
              color: #26ca9a;
              padding-left: 3px;
            }
          }
        }
      }
    }
    .transaction-wrapper{
      width: 844px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
      border: solid 1px #eaedf2;
      background-color: #ffffff;
      .transaction{
        width: 783px;
        margin: 20px auto;
        .description-box{
          width: 783px;
          border-radius: 10px;
          background-color: #eff3f8;
          text-align: center;
          .description {
            width: 620px;
            margin: 0 auto;
            padding: 12px 0;
            font-size: 14px;
            font-weight: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #2d3640;
          }
        }
        .transaction-footer{
          margin-top: 22px;
          display: flex;
          .live-analyse-button{
            text-align: center;
            cursor: pointer;
            width: 185px;
            height: 30px;
            line-height: 29px;
            border-radius: 4px;
            background-color: #5a26a4;
            color: #ffffff;
            font-size: 13px;
            display: flex;
            .analytics-icon /deep/{
              width: 16px;
              height: 16px;
              path {
                fill: #ffffff!important;
              }
            }
            &.active{
              background-color: #fd4056;
            }
          }
          .analyse-label{
            text-align: center;
            width: 125px;
            height: 28px;
            line-height: 27px;
            border-radius: 4px;
            border: solid 1px #5a26a4;
            font-size: 13px;
            background-color: #ffffff;
            color: #5a26a4;
            &.completed{
              border: solid 1px #6dce6a;
              color: #6dce6a;
            }
          }
        }
        .separator{
          margin-top: 20px;
          width: 100%;
          height: 1px;
          background-color: rgba(0, 0, 0, 0.1);
        }
        .analytics{
          margin-top: 30px;
          display: flex;
          justify-content: center;
          .item{
            &:nth-child(n+2) {
              margin-left: 68px;
            }
            .title {
              display: flex;
              height: 22px;
              font-size: 16px;
              font-weight: normal;
              line-height: 1.38;
              color: #8b95a1;
              &.active{
                color: #2d3640;
              }
              .info-icon /deep/{
                margin-top: 4px;
                width: 14px;
                height: 14px;
                path {
                  fill: #8b95a1!important;
                }
              }
              .info-icon-active /deep/{
                margin-top: 4px;
                width: 14px;
                height: 14px;
                path {
                  fill: #2d3640!important;
                }
              }
              .data-balloon{

                display: flex;
                cursor: help;
                justify-content: center;
              }
            }
            .value{
              height: 42px;
              font-size: 34px;
              font-weight: bold;
              letter-spacing: -0.35px;
              color: #5e6b79;
              text-align: center;
              &.active{
                color: #2d3640;
              }
              &.active-green{
                color: #00a575 !important;
              }
            }
          }
        }
      }
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
